<script>
import HorizontalMenu from '@/components/general/HorizontalMenu'
import Breadcrumbs from '@/components/general/Breadcrumbs'
export default {
  name: 'AdvertisingIntegrations',
  components: {
    HorizontalMenu,
    Breadcrumbs
  },
  data () {
    return {
      selectedMenu: 0,
      menuOptions: [
        { to: { name: 'business.admin.center.advertising.integrations.ads' }, text: 'admin.center.advertising.integrations:menu.ads', icon: 'mdi-bullhorn' },
        { to: { name: 'business.admin.center.advertising.integrations.integrations' }, text: 'admin.center.advertising.integrations:menu.integration', icon: 'mdi-sitemap' }
      ]
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'admin.center:menu.5:title', href: this.$route.path }]
      return path
    }
  }
}
</script>
<template>
  <section class="advertising-integrations--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths"/>
      <h2>{{ $t('admin.center:advertising.integrations.title') }}</h2>
    </div>
    <horizontal-menu :options="menuOptions" v-model="selectedMenu"></horizontal-menu>
    <router-view></router-view>
  </section>
</template>
<style lang="scss">
.advertising-integrations--container {
  .horizontal-menu--container {
    margin-bottom: 8px;
  }
}
</style>
